import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// auth
import { useAuthContext } from 'src/auth/hooks';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Logo from 'src/components/logo';

// ----------------------------------------------------------------------
export default function AuthClassicLayout({ children, image, title }) {
  const { method } = useAuthContext();
  const theme = useTheme();
  const upMd = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        top: 16,
        left: 16,
      }}
    />
  );

  const renderContent = (
    <Box
      sx={{
        bgcolor: 'black', // Set the background color to black
        minHeight: '100vh', // Ensure the container takes at least full viewport height
        display: 'flex', // Flexbox for alignment
        justifyContent: 'center', // Center align horizontally
      }}
    >
      <Stack
        sx={{
          width: { xs: 1, sm: 1, md: 480, lg: 480 },
          mx: 'auto',
          maWidth: 480,
          bgcolor: 'black', // Redundant here since the parent already has black background
          px: { xs: 2, md: 8 },
          py: { xs: 15, md: 30 },
          minHeight: '100vh', // Ensure the stack itself also fills the viewport height 
        }}
      >
        {children}
      </Stack>
    </Box>
  );

  const renderSection = (
    <Box
      sx={{
        position: 'relative',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column', // This ensures stacking of elements (text on top, video below)
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'grey.900',
        overflow: 'hidden',
      }}
    >
      {/* Text section - Positioned above the video */}
      <Stack
        sx={{
          zIndex: 3, // Ensures the text is above the video
          textAlign: 'center',
          color: 'white',
          p: 4,
        }}
      >
        <Typography variant="h3" sx={{ mb: 2, fontSize: '1.15em' }}>
          {title || 'Bring Virtual 3D to Your Reality'}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: '1.15em' }}>
          Place, Explore, and Interact with 3D Models Instantly in VR and AR
        </Typography>
      </Stack>

      {/* Video section - Positioned below the text */}
      <Box
        component="video"
        src="https://video.wixstatic.com/video/4b94e4_cd26ed8081c94472b91a1b24bf5f37d1/480p/mp4/file.mp4"
        role="presentation"
        autoPlay
        muted
        loop
        preload="auto"
        playsInline
        sx={{
          width: '70%', // Set the video width to 85%
          height: 'auto',
          borderRadius: '20px',
          objectFit: 'cover',
          zIndex: 1,
        }}
      />

      {/* Dark overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.5)', // Dark overlay for readability
          zIndex: 2, // Ensures the overlay is above the video but below the text
        }}
      />
    </Box>
  );




  return (
    <Stack
      component="main"
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        minHeight: '100%',
        backgroundColor: theme.palette.background.default,
      }}
    >
      {renderLogo}
      {upMd && renderSection}
      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
